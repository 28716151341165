<template>
  <div id="app">
    <div class="background">
      <div class="login-container">
        <router-link to="/" v-if="isLoggedIn">Home</router-link>
        <n-button v-if="isLoggedIn" @click="logout">注销</n-button>

        <div class="login-form" v-if="!isLoggedIn">
          <n-card title="登录" class="custom-card">
            <n-tabs default-value="signin" size="large" justify-content="space-evenly">
              <n-tab-pane name="signin" tab="登录" size="large">
                <n-form @submit.prevent="login">
                  <n-form-item label="用户名" size="large" class="custom-form-item">
                    <input
                      type="text"
                      v-model="signinUsername"
                      placeholder="请输入用户名"
                      class="custom-input"
                    />
                  </n-form-item>
                  <n-form-item label="密码" size="large" class="custom-form-item">
                    <input
                      type="password"
                      v-model="signinPassword"
                      placeholder="请输入密码"
                      class="custom-input"
                    />
                  </n-form-item>
                  <n-button type="primary" block @click.prevent="login">登录</n-button>
                </n-form>
              </n-tab-pane>

              <n-tab-pane name="signup" tab="注册" size="large">
                <n-form @submit.prevent="signup">
                  <n-form-item label="用户名" size="large" class="custom-form-item">
                    <input
                      type="text"
                      v-model="signupUsername"
                      placeholder="请输入用户名"
                      class="custom-input"
                    />
                  </n-form-item>
                  <n-form-item label="密码" size="large" class="custom-form-item">
                    <input
                      type="password"
                      v-model="signupPassword"
                      placeholder="请输入密码"
                      class="custom-input"
                    />
                  </n-form-item>
                  <n-form-item label="重复密码" size="large" class="custom-form-item">
                    <input
                      type="password"
                      v-model="repeatPassword"
                      placeholder="请重复输入密码"
                      class="custom-input"
                    />
                  </n-form-item>
                  <n-button type="primary" block @click.prevent="signup">注册</n-button>
                </n-form>
              </n-tab-pane>
            </n-tabs>
          </n-card>
        </div>

        <router-view v-if="isLoggedIn" class="full-page" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      isLoggedIn: false,
      signinUsername: '',
      signinPassword: '',
      signupUsername: '',
      signupPassword: '',
      repeatPassword: '',
    };
  },
  methods: {
    async login() {
      try {
        console.log('登录数据:', this.signinUsername, this.signinPassword); // 调试日志
        const response = await axios.post('http://localhost:3000/api/login', {
          username: this.signinUsername,
          password: this.signinPassword,
        });
        if (response.data.success) {
          this.isLoggedIn = true;
          console.log('登录成功'); // 调试日志
          // 可选：保存 token 或其他认证信息
        } else {
          alert('登录失败：' + response.data.message);
        }
      } catch (error) {
        console.error('登录错误', error);
      }
    },
    async signup() {
      if (this.signupPassword !== this.repeatPassword) {
        alert('两次输入的密码不一致');
        return;
      }
      try {
        console.log('注册数据:', this.signupUsername, this.signupPassword); // 调试日志
        const response = await axios.post('http://localhost:3000/api/signup', {
          username: this.signupUsername,
          password: this.signupPassword,
        });
        if (response.data.success) {
          alert('注册成功');
        } else {
          alert('注册失败：' + response.data.message);
        }
      } catch (error) {
        console.error('注册错误', error);
      }
    },
    logout() {
      this.isLoggedIn = false;
      // 可选：清除 token 或其他认证信息
    },
  },
};
</script>

<style>
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/background.jpg');
  background-size: cover;
  background-position: center;
}

.login-container {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 20px;
}

.login-form {
  width: 400px;
  background-color: rgba(255, 182, 193, 0.8);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
}

.custom-card {
  background-color: rgba(255, 182, 193, 0.8);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.custom-form-item {
  background-color: rgba(255, 182, 193, 0.8);
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 10px;
}

.custom-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: rgba(255, 192, 203, 0.8);
}

.full-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
