<template>
  <div class="carousel-container">
    <n-carousel
      dot-placement="bottom"
      mousewheel
      :autoplay="3000"
      style="width: 100%; height: 100%;"
    >
      <img
        v-for="(image, index) in images"
        :key="index"
        :src="image"
        class="carousel-img"
        alt="Role Image"
      >
    </n-carousel>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RoleCarousel',
  data() {
    return {
      images: [
        require('@/assets/roles/role1.jpg'),
        require('@/assets/roles/role2.jpg'),
        require('@/assets/roles/role3.jpg'),
        require('@/assets/roles/role4.jpg'),
        require('@/assets/roles/role5.jpg'),
        require('@/assets/roles/role6.jpg'),
        require('@/assets/roles/role7.jpg'),
        require('@/assets/roles/role8.jpg'),
        require('@/assets/roles/role9.jpg'),
        require('@/assets/roles/role10.jpg'),
        require('@/assets/roles/role11.jpg'),
        require('@/assets/roles/role12.jpg')
      ]
    };
  }
});
</script>

<style scoped>
.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px; /* 可根据需要调整最大宽度 */
  height: 100vh; /* 设置为视口高度的80%，或根据需要调整 */
  margin: 0 auto;
  overflow: hidden; /* 确保超出部分不显示 */
}

.carousel-img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* 确保图片按比例缩放，不被拉伸 */
  display: block;
}
</style>
