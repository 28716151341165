import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import AboutPage from '../views/AboutPage.vue';
import LoginPage from '../views/LoginPage.vue'; // 假设你有登录页面

const routes = [
  { path: '/', component: HomePage, meta: { requiresAuth: true } }, // 需要验证登录状态
  { path: '/about', component: AboutPage },
  { path: '/login', component: LoginPage }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = true; // 这里假设用户已登录，实际应用中需要根据实际情况判断
  if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
    next('/login'); // 需要登录验证但未登录，则重定向到登录页
  } else {
    next(); // 放行
  }
});

export default router;