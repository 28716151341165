<template>
  <div class="outer-container">
    <div class="image-gallery">
      <n-infinite-scroll :distance="10" @load="handleLoad">
        <div v-for="image in images" :key="image.id" class="image-container">
          <img :src="image.src" :alt="'Image ' + image.id" />
        </div>
      </n-infinite-scroll>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const images = ref([]);
    const imageCount = 6; // 每次加载的图片数量
    const totalImages = 20; // 总共可加载的图片数量
    const isLoading = ref(false); // 防止重复加载

    const loadImages = () => {
      const currentCount = images.value.length;
      if (currentCount >= totalImages) return; // 防止加载超过最大图片数量

      const nextCount = Math.min(currentCount + imageCount, totalImages);
      isLoading.value = true;

      setTimeout(() => {
        for (let i = currentCount + 1; i <= nextCount; i++) {
          images.value.push({ id: i, src: require(`@/assets/image/image${i}.jpg`) });
        }
        isLoading.value = false;
      }, 1000); // 模拟加载时间
    };

    const handleLoad = () => {
      if (images.value.length < totalImages && !isLoading.value) {
        loadImages();
      }
    };

    loadImages(); // 初始化加载图片

    return {
      images,
      handleLoad
    };
  }
});
</script>

<style scoped>
.outer-container {
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(800px, 1fr)); /* 设置最小宽度为200px，并根据空间自动调整 */
  gap: 10px; /* 图片间距 */
  max-width: 100vw; /* 画廊最大宽度为60%屏幕宽度 */
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  height: calc(100vh - 40px);
}

.image-container {
  background-color: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
}
</style>
