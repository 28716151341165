<template>
  <div class="about-us">
    <div class="about-us-header">
      <h1>About Us</h1>
    </div>
    <div class="about-us-content">
      <div class="about-us-image">
        <img src="@/assets/about-us-image.jpg" alt="About Us" />
      </div>
      <div class="about-us-text">
        <div class="text-box">
          <p>十三个矛盾的故事，十三个背弃了自己命运的人。</p>
          <p>让那无限的梅比乌斯，摈弃永生的希望。</p>
          <p>让那旭光的科斯魔，放下来一生所背负的两难。</p>
          <p>让那浮生的华，遗忘了自己一生的人，可以做到什么，能够做到什么。</p>
          <p>让那鏖灭的千劫，终于能够保护到什么。</p>
          <p>让那刹那的樱，一生都蒙受孤独、落寞、懊悔的人，得以坚守。</p>
          <p>让那空梦的帕朵菲莉丝，凡人的极致背弃自己的理想，去相信爱莉希雅。</p>
          <p>让那戒律的阿波尼亚，被命运丝线紧束的人，得以重新纺织自己的命运。</p>
          <p>让那黄金的伊甸永驻瞬间，让那繁星的格蕾修陪伴，让那螺旋的维尔薇演出，</p>
          <p>让那救世和天慧的凯文、苏，成为英雄、医师。</p>
          <p>“无关乎人类的未来，无关乎万众的理想。”</p>
          <p>“这一次，我将自己的生命压进枪膛——只为，拯救‘一人’。”</p>
          <p>最后再让爱一切的爱莉希雅，得到众人的爱戴。</p>
          <p>没有一个人愧对十三英桀之名。</p>
          <p>十二个刻印，代表了人世间十二种美好。</p>
          <p>救世，代表人类坚定的意志。</p>
          <p>戒律，代表人类的自我约束。</p>
          <p>黄金，代表人类的精神向往。</p>
          <p>螺旋，代表人类的自我批判。</p>
          <p>鏖灭，代表人类对邪恶和不公无尽的抗争。</p>
          <p>天慧，代表人类尊崇自然规律。</p>
          <p>刹那，代表人类守护瞬间的美好。</p>
          <p>旭光，代表人类永远拥有希望。</p>
          <p>无限，代表人类无尽的求知欲。</p>
          <p>繁星，代表人类无拘无束的幻想。</p>
          <p>浮生，代表人类脚踏实地的工作态度。</p>
          <p>空梦，代表人类对未来美好的幻想。</p>
          <p>爱莉希雅走遍世界，自然黑的白的善的恶的都见过，但她最终就像妖精采花一样，将这代表十二种美好的人聚集在一起，代表了她对人世间的美好深信不疑且视如珍宝。</p>
          <p>最终，她离去的时候又将这些美好撒向大地，在遥远的后世生根发芽。这是一个爱戴所有人的女孩，又被所有人爱戴的故事。</p>
          <p>所以我想，故事并没有终结，虽然侵蚀之律者费尽心思都没有逃出往世乐土，但也许爱莉希雅的故事不但走出了乐土，甚至突破了次元壁，我相信爱莉希雅的故事一定会在现实中延续下去吧。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUsComponent",
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comic+Sans+MS:wght@400;700&display=swap');

.about-us {
  padding: 20px;
  font-family: 'Comic Sans MS', cursive, sans-serif;
}

.about-us-header {
  text-align: left;
  margin-bottom: 20px;
}

.about-us-header h1 {
  font-size: 3em;
  font-weight: bold;
  color: #333;
}

.about-us-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

.about-us-image {
  flex: 1;
  text-align: center;
}

.about-us-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.about-us-text {
  flex: 2;
  max-width: 600px;
}

.text-box {
  border: 2px solid #ff69b4;
  border-radius: 12px;
  padding: 20px;
  background-color: rgba(255, 182, 193, 0.3); /* 淡粉色，带透明度 */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* 启用滚动条 */
  max-height: 400px; /* 限制最大高度 */
  font-family: 'Comic Sans MS', cursive, sans-serif; /* 确保文本框内字体应用 */
}

.text-box p {
  font-size: 1.1em;
  line-height: 1.8;
  color: #555;
}
</style>
