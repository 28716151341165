<template>
  <div>
    <n-card title="登录">
      <n-tabs default-value="signin" size="large" justify-content="space-evenly">
        <n-tab-pane name="signin" tab="登录" size="large">
          <n-form ref="signinForm" @submit.prevent="login">
            <n-form-item label="用户名" size="large">
              <n-input v-model="signinUsername" placeholder="请输入用户名" />
            </n-form-item>
            <n-form-item label="密码" size="large">
              <n-input
                v-model="signinPassword"
                placeholder="请输入密码"
                type="password"
                :show-password="true"
              />
            </n-form-item>
            <n-button type="primary" block @click="login">
              登录
            </n-button>
          </n-form>
        </n-tab-pane>
        <n-tab-pane name="signup" tab="注册" size="large">
          <n-form ref="signupForm" @submit.prevent="signup">
            <n-form-item label="用户名" size="large">
              <n-input v-model="signupUsername" placeholder="请输入用户名" />
            </n-form-item>
            <n-form-item label="密码" size="large">
              <n-input
                v-model="signupPassword"
                placeholder="请输入密码"
                type="password"
                :show-password="true"
              />
            </n-form-item>
            <n-form-item label="重复密码" size="large">
              <n-input
                v-model="repeatPassword"
                placeholder="请重复输入密码"
                type="password"
                :show-password="true"
              />
            </n-form-item>
            <n-button type="primary" block @click="signup">
              注册
            </n-button>
          </n-form>
        </n-tab-pane>
      </n-tabs>
    </n-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      signinUsername: '',
      signinPassword: '',
      signupUsername: '',
      signupPassword: '',
      repeatPassword: ''
    }
  },
  methods: {
    login() {
      console.log('Login button clicked');  // Debug log
      this.$router.push('/').catch(err => console.error(err));  // Redirect to homepage
    },
    signup() {
      // Implement signup logic here
    }
  }
}
</script>


