<template>
  <n-space vertical>
    <n-layout has-sider style="background-color: rgba(255, 192, 203, 0.5);">
      <n-layout-sider
        bordered
        collapse-mode="width"
        :collapsed-width="64"
        :width="240"
        :collapsed="collapsed"
        show-trigger
        @collapse="collapsed = true"
        @expand="collapsed = false"
        style="background-color: rgba(255, 192, 203, 0.5);">
        <n-menu
          :collapsed="collapsed"
          :collapsed-width="64"
          :collapsed-icon-size="22"
          :options="menuOptions"
          :render-label="renderMenuLabel"
          :render-icon="renderMenuIcon"
          :expand-icon="expandIcon"
          v-model:value="activeKey"
        />
      </n-layout-sider>
      <n-layout style="background-color: rgba(255, 192, 203, 0.5);">
        <div id="top-menu" style="height: 42px;">
          <n-menu
            v-model:value="activeKey"
            mode="horizontal"
            :options="menuOptionsHorizontal"
            responsive
          />
        </div>
        <div id="content-container" style="padding: 16px;">
          <component :is="currentComponent" />
        </div>
      </n-layout>
    </n-layout>

    <!-- 头像上传对话框 -->
    <n-modal v-model:show="showAvatarModal" title="上传头像" size="small">
      <div style="text-align: center;">
        <img :src="avatarUrl" alt="Current Avatar" style="width: 100px; height: 100px; border-radius: 50%;" />
        <n-upload
          accept="image/*"
          :on-change="handleAvatarUpload"
          show-file-list="false"
          style="margin-top: 20px;"
        >
          <n-button>上传新头像</n-button>
        </n-upload>
      </div>
    </n-modal>
  </n-space>
</template>

<script>
import { defineComponent, h, ref, computed } from "vue";
import { NIcon } from "naive-ui";
import { PeopleOutline, PersonOutline, ImagesOutline, HeartOutline, NotificationsOutline, ChatboxOutline, LogOutOutline, CaretDownOutline } from "@vicons/ionicons5";
import AboutUsComponent from './AboutUsComponent.vue';
import RolesComponent from './RolesComponent.vue';
import IllustratedBookComponent from './IllustratedBookComponent.vue';
import BlessingsComponent from './BlessingsComponent.vue';

const menuOptions = [
  { label: "关于我们", key: "about-us", icon: PeopleOutline, component: AboutUsComponent },
  { label: "角色", key: "roles", icon: PersonOutline, component: RolesComponent },
  { label: "照片墙", key: "illustrated-book", icon: ImagesOutline, component: IllustratedBookComponent },
  { label: "祝福", key: "blessings", icon: HeartOutline, component: BlessingsComponent },
];

function renderIcon(icon) {
  return () => h(NIcon, null, { default: () => h(icon) });
}

export default defineComponent({
  setup() {
    const collapsed = ref(true);
    const activeKey = ref(menuOptions[0].key);
    const showAvatarModal = ref(false);
    const avatarUrl = ref('/avatar.ico');

    const currentComponent = computed(() => {
      const selectedOption = menuOptions.find(option => option.key === activeKey.value);
      return selectedOption ? selectedOption.component : null;
    });

    const handleAvatarClick = () => {
      showAvatarModal.value = true;
    };

    const handleAvatarUpload = (file) => {
      if (file && file.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          avatarUrl.value = e.target.result;
        };
        reader.readAsDataURL(file.file);
      }
    };

    const menuOptionsHorizontal = [
      {
        label: "",
        key: "avatar",
        icon: () => h('img', { src: avatarUrl.value, alt: 'Avatar', style: { width: '22px', height: '22px', borderRadius: '50%' } }),
        onClick: handleAvatarClick
      },
      {
        label: "通知",
        key: "messages",
        icon: renderIcon(NotificationsOutline)
      },
      {
        label: "动态",
        key: "activities",
        icon: renderIcon(ChatboxOutline)
      },
      {
        label: "聊天室",
        key: "chat",
        icon: renderIcon(ChatboxOutline)
      },
      {
        label: "退出",
        key: "logout",
        icon: renderIcon(LogOutOutline)
      }
    ];

    return {
      collapsed,
      menuOptions,
      menuOptionsHorizontal,
      activeKey,
      showAvatarModal,
      avatarUrl,
      handleAvatarClick,
      handleAvatarUpload,
      currentComponent,
      renderMenuLabel(option) {
        if ("href" in option) {
          return h(
            "a",
            { href: option.href, target: "_blank" },
            option.label
          );
        }
        return option.label;
      },
      renderMenuIcon(option) {
        return h(NIcon, null, { default: () => h(option.icon) });
      },
      expandIcon() {
        return h(NIcon, null, { default: () => h(CaretDownOutline) });
      }
    };
  }
});
</script>

<style scoped>
#content-container {
  background-color: rgba(255, 192, 203, 0.5);
  height: 100vh;
  overflow: hidden;
}
</style>
