<template>
  <div :class="`container background-${currentIndex + 1}`">
    <div class="content-box">
      <div class="arrows">
        <button @click="handlePrevContent">&larr;</button>
        <button @click="handleNextContent">&rarr;</button>
      </div>
      <div 
        class="text-box" 
        @touchstart="touchStart" 
        @touchmove="touchMove" 
        @touchend="touchEnd"
      >
        <img :src="currentAvatarUrl" class="avatar" alt="Avatar" />
        <p>{{ currentContent.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';

// 导入所有的头像图片
import avatar1 from '@/assets/avatar/avatar1.jpg';
import avatar2 from '@/assets/avatar/avatar2.jpg';
import avatar3 from '@/assets/avatar/avatar3.jpg';
import avatar4 from '@/assets/avatar/avatar4.jpg';
import avatar5 from '@/assets/avatar/avatar5.jpg';
import avatar6 from '@/assets/avatar/avatar6.jpg';
import avatar7 from '@/assets/avatar/avatar7.jpg';
import avatar8 from '@/assets/avatar/avatar8.jpg';
import avatar9 from '@/assets/avatar/avatar9.jpg';
import avatar10 from '@/assets/avatar/avatar10.jpg';
import avatar11 from '@/assets/avatar/avatar11.jpg';
import avatar12 from '@/assets/avatar/avatar12.jpg';
import avatar13 from '@/assets/avatar/avatar13.jpg';

export default defineComponent({
  setup() {
    const contents = ref([
      { id: 1, text: '愿无烬之剑爱佑身，赐予「救世」的理想' },
      { id: 2, text: '愿无暇之人爱佑身，赐予「真我」的回归' },
      { id: 3, text: '愿深罪之槛爱佑身，赐予「戒律」的慈爱' },
      { id: 4, text: '愿璀耀之歌爱佑身，赐予「黄金」的光辉' },
      { id: 5, text: '愿愚戏之匣爱佑身，赐予「螺旋」的奇迹' },
      { id: 6, text: '愿坏劫之焱爱佑身，赐予「鏖灭」的赤诚' },
      { id: 7, text: '愿善法之瞳爱佑身，赐予「天慧」的智识' },
      { id: 8, text: '愿寸断之刃爱佑身，赐予「刹那」的决绝' },
      { id: 9, text: '愿黎明之哨爱佑身，赐予「旭光」的意志' },
      { id: 10, text: '愿噬界之蛇爱佑身，赐予「无限」的渴求' },
      { id: 11, text: '愿绘世之卷爱佑身，赐予「繁星」的纯真' },
      { id: 12, text: '愿渡尘之羽爱佑身，赐予「浮生」的坚韧' },
      { id: 13, text: '愿掠集之兽爱佑身，赐予「空梦」的自由' },
    ]);

    const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8, avatar9, avatar10, avatar11, avatar12, avatar13];
    const currentIndex = ref(0);
    const startX = ref(0);

    const currentContent = computed(() => {
      return contents.value[currentIndex.value] || {};
    });

    const currentAvatarUrl = computed(() => {
      return avatars[currentIndex.value] || avatars[0];
    });

    const updateContent = (index) => {
      currentIndex.value = Math.min(Math.max(index, 0), contents.value.length - 1);
    };

    const handlePrevContent = () => {
      if (currentIndex.value > 0) {
        updateContent(currentIndex.value - 1);
      }
    };

    const handleNextContent = () => {
      if (currentIndex.value < contents.value.length - 1) {
        updateContent(currentIndex.value + 1);
      }
    };

    const touchStart = (event) => {
      startX.value = event.touches[0].clientX;
    };

    const touchMove = (event) => {
      const endX = event.touches[0].clientX;
      const difference = startX.value - endX;

      if (difference > 50) {
        handleNextContent();
      } else if (difference < -50) {
        handlePrevContent();
      }
    };

    const touchEnd = () => {
      startX.value = 0;
    };

    return {
      contents,
      currentContent,
      currentIndex,
      currentAvatarUrl,
      handlePrevContent,
      handleNextContent,
      touchStart,
      touchMove,
      touchEnd,
    };
  }
});
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background-size: cover;
  background-position: center;
  transition: background-image 0.5s ease-in-out;
}

.content-box {
  position: relative;
  width: 60%;
  max-width: 800px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.arrows {
  position: absolute;
  top: 10px;
  right: 10px;
}

.arrows button {
  font-size: 1.5em;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 10px;
}

.text-box {
  margin-top: 20px;
  position: relative;
}

.text-box p {
  font-size: 1.2em;
  color: #333;
}

.avatar {
  position: absolute;
  top: 10px; /* 调整头像位置 */
  right: 20px; /* 调整头像位置 */
  width: 100px; /* 放大头像 */
  height: 100px; /* 放大头像 */
  border-radius: 50%;
}

/* Background classes for each content */
.background-1 { 
  background-image: url('../assets/backgrounds/bg1.png'); 
  background-size: 50%; /* 缩小到原始尺寸的50% */
  background-position: center; /* 居中对齐 */
  background-repeat: no-repeat; /* 不重复 */
}
.background-2 { 
  background-image: url('../assets/backgrounds/bg2.png'); 
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.background-3 { 
  background-image: url('../assets/backgrounds/bg3.png'); 
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.background-4 { 
  background-image: url('../assets/backgrounds/bg4.png'); 
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.background-5 { 
  background-image: url('../assets/backgrounds/bg5.png'); 
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.background-6 { 
  background-image: url('../assets/backgrounds/bg6.png'); 
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.background-7 { 
  background-image: url('../assets/backgrounds/bg7.png'); 
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.background-8 { 
  background-image: url('../assets/backgrounds/bg8.png'); 
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.background-9 { 
  background-image: url('../assets/backgrounds/bg9.png'); 
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.background-10 { 
  background-image: url('../assets/backgrounds/bg10.png'); 
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.background-11 { 
  background-image: url('../assets/backgrounds/bg11.png'); 
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.background-12 { 
  background-image: url('../assets/backgrounds/bg12.png'); 
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.background-13 { 
  background-image: url('../assets/backgrounds/bg13.png'); 
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}


</style>
